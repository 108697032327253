import React, { useState } from 'react'
import { ITodo } from '../models/todo'
import { makeStyles, styled, ThemeProvider } from '@material-ui/styles'
import { Box, Container, FilledInput, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core'
import theme from '../styled/Theme'
import { GlobalStyle } from '../styled/GlobalStyle'
import BeerImg from '../assets/beer2.jpg'
import Logo from '../assets/logo.gif'
import { Search } from '@material-ui/icons'
import FormControl from '@material-ui/core/FormControl'
import { Field, Form } from 'react-final-form'
import { navigate } from 'gatsby'
import BreweriesMap from './breweries/map'

const imgStyle = { maxWidth: '300px', marginBottom: '1.45rem' }

const pStyle = {
  fontSize: 'calc(5px + 3vw)',
  lineHeight: 'calc(12px + 3vw)',
  margin: '2px',
}

const Hero = styled(Box)(({ img }) => ({
  height: '570px',
  backgroundImage: `url(${img})`,
  backgroundSize: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}))

const useStyles = makeStyles({
  logo: {
    maxHeight: '10em',
  },
  input: {
    // backgroundColor: 'white',
    width: '200px',
    '&:first-of-type': {
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
    },
    '&:last-of-type': {
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
    },
    '&:before': {
      borderBottom: 'none !important',
    },
  },
})

const searchTypes = [
  {
    value: 'name',
    label: 'Name',
  },
  {
    value: 'city',
    label: 'City',
  },
]

const IndexPage = ({ todos = [], userId = 0 }: { todos: ITodo[] | undefined, userId: number | undefined }) => {
  return <BreweriesMap/>

  const classes = useStyles()
  const [redirect, setRedirect] = useState<string | undefined>()
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle/>
      <Container maxWidth={false} disableGutters={true}
                 style={{ height: '100vh', flexDirection: 'column', display: 'flex' }}>
        <Hero img={BeerImg}>
          <img className={classes.logo} alt={'Looking for Beer Logo'} src={Logo}/>
          <Form
            onSubmit={(values) => {
              return navigate(`/breweries?search=${encodeURI(values.search.toLowerCase())}&type=${encodeURI(values.type)}`)
            }}
          >
            {props => (
              <form onSubmit={props.handleSubmit}>
                <Field name={'search'}>
                  {({ input }) => (
                    <FormControl variant="filled" className={classes.input}>
                      <InputLabel htmlFor="component-filled">Search</InputLabel>
                      <FilledInput id="component-filled" {...input}/>
                    </FormControl>
                  )}
                </Field>
                <Field name={'type'}>
                  {({ input }) => (
                    <FormControl variant="filled" className={classes.input}>
                      <InputLabel htmlFor="type">Search By</InputLabel>
                      <Select
                        id="type"
                        variant="filled"
                        value={input.value}
                        onChange={input.onChange}
                        aria-label={'Search By'}
                      >
                        {searchTypes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
                <IconButton aria-label="search" className={classes.input} type={'submit'}>
                  <Search fontSize="large"/>
                </IconButton>
              </form>
            )}
          </Form>
        </Hero>
        {/*<SEO title='Home'/>*/}
        {/*<div style={imgStyle}>*/}
        {/*  <Image/>*/}
        {/*</div>*/}

        {/*<div style={pStyle}>*/}
        {/*  <p>Welcome to your new Gatsby site...</p>*/}
        {/*  <p>Now go build something great!</p>*/}
        {/*</div>*/}

        {/*<LoginSection userId={userId}/>*/}

        {/*<Todos todos={todos}/>*/}

        {/*<Link to='/page-2/'>Go to page 2</Link>*/}
        {/*<Link to='/app/breweries/'>Breweries</Link>*/}
        {/*<Router>*/}
        {/*  <Brewery path="/brewery/:id"/>*/}
        {/*</Router>*/}
      </Container>
    </ThemeProvider>
  )
}

export default IndexPage
